import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Utilities from '../../services/Utilities';
import './PageContent.scss';

class PageContent extends Component {
  componentDidMount() {
    Utilities.scrollTop();
  }

  render() {
    const { children, className, pageTitle } = this.props;

    return (
      <>
        {pageTitle && (
          <header className="PageContent--hero-banner">
            <div className="container">
              <h1 className="PageContent--title" id="main-ada">
                {pageTitle}
              </h1>
            </div>
          </header>
        )}
        <main className={classNames('PageContent', className)}>{children}</main>
      </>
    );
  }
}

PageContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  pageTitle: PropTypes.string,
};

PageContent.defaultProps = {
  className: '',
  pageTitle: null,
};

export default PageContent;
