import { compile } from 'path-to-regexp';
import generateRoutes from './utils/routing-utils';

import artIcon from './assets/icons/art_icon.svg';
import literatureIcon from './assets/icons/literature_icon.svg';
import naturalScienceIcon from './assets/icons/natural_sciences_icon.svg';
import historyIcon from './assets/icons/history_icon.svg';
import sportsIcon from './assets/icons/sports_icon.svg';
import geographyIcon from './assets/icons/geography_icon.svg';
import mathIcon from './assets/icons/math_icon.svg';
import religionIcon from './assets/icons/religion_icon.svg';
// Games Browse Icons
import languageIcon from './assets/icons/language_icon.svg';
import mathematicsIcon from './assets/icons/mathematics_icon.svg';
import scienceIcon from './assets/icons/science_icon.svg';
import socialStudiesIcon from './assets/icons/social_studies_icon.svg';
// Navigation Menu Images
import azL2 from './assets/images/eb_prtgs_escola3.5_home_l2_01.png';
import azL3 from './assets/images/eb_prtgs_escola3.5_home_l3_01.jpg';
import imagensL2 from './assets/images/eb_prtgs_escola3.5_home_l2_02.png';
import imagensL3 from './assets/images/eb_prtgs_escola3.5_home_l3_02.jpg';
import videotecaL2 from './assets/images/eb_prtgs_escola3.5_home_l2_03.png';
import videotecaL3 from './assets/images/eb_prtgs_escola3.5_home_l3_03.jpg';
import biografiasL2 from './assets/images/eb_prtgs_escola3.5_home_l2_04.png';
import biografiasL3 from './assets/images/eb_prtgs_escola3.5_home_l3_04.jpg';
import brasilL2 from './assets/images/eb_prtgs_escola3.5_home_l2_06.png';
import brasilL3 from './assets/images/eb_prtgs_escola3.5_home_l3_06.jpg';
import azL1 from './assets/images/eb_prtgs_escola3.5_home_l1_01.png';
import biografiasL1 from './assets/images/eb_prtgs_escola3.5_home_l1_02.png';
import videotecaL1 from './assets/images/eb_prtgs_escola3.5_home_l1_03.png';
import atlasL1 from './assets/images/eb_prtgs_escola3.5_home_l1_04.png';
import brasilL1 from './assets/images/eb_prtgs_escola3.5_home_l1_05.png';
import jogosL1 from './assets/images/eb_prtgs_escola3.5_home_l1_06.png';

export const Levels = {
  ELEMENTARY: 'anosiniciais',
  MIDDLE: 'anosfinais',
  HIGH: 'ensinomedio',
};

export const LevelTitles = {
  anosiniciais: 'anos iniciais',
  anosfinais: 'anos finais',
  ensinomedio: 'ensino médio',
};

export const LevelFullNames = {
  ELEMENTARY: 'EF ANOS INICIAIS',
  MIDDLE: 'EF ANOS FINAIS',
  HIGH: 'ENSINO MÉDIO',
};

export const RoutePath = {
  ARTICLE: '/artigo/:title/:articleId',
  BROWSE_ALPHA: '/seção/alfa/:alphaType/:alphaLetter/:pageNumber',
  BROWSE_ANIMALS: '/seção/animais/:theme/:subject/:topic?',
  BROWSE_ATLAS: '/seção/atlas/:isoCode?',
  BROWSE_HELP: '/seção/ajuda/:theme/:subject?/:topic?',
  BROWSE_GAMES: '/seção/jogos/:theme/:subject',
  BROWSE_THEME: '/seção/tema/:theme/:subject?/:topic*',
  BROWSE_VIDEOS: '/seção/vídeos/:theme',
  SEARCH_WIDGET: '/widgets/searchbox',

  GAMES_LINK: '',

  HOME: '/',
  HELP: '/ajuda',

  MEDIA_OVERLAY: '/recursos/:assemblyId',
  SEARCH: '/pesquisa/:searchType/:searchQuery',

  TEST: '/test',
  SAMPLE_PAGE: '/sample',

  DEEPLINK_HOME: '/deeplink/home',
};

// Start using generateRoutes helper for "MY_BRITANNICA". We will eventually convert all our routes using this helper.
export const Routes = generateRoutes({
  MY_BRITANNICA: '/minhabritannica/:collectionId?',
  SHARED_COLLECTION: '/coleçãocompartilhada/:collectionId',
});

export const CompiledPath = {
  ARTICLE: compile(RoutePath.ARTICLE),
  BROWSE_ALPHA: compile(RoutePath.BROWSE_ALPHA),
  BROWSE_ANIMALS: compile(RoutePath.BROWSE_ANIMALS),
  BROWSE_ATLAS: compile(RoutePath.BROWSE_ATLAS),
  BROWSE_HELP: compile(RoutePath.BROWSE_HELP),
  BROWSE_GAMES: compile(RoutePath.BROWSE_GAMES),
  BROWSE_THEME: compile(RoutePath.BROWSE_THEME),
  BROWSE_VIDEOS: compile(RoutePath.BROWSE_VIDEOS),
  MEDIA_OVERLAY: compile(RoutePath.MEDIA_OVERLAY),
  SEARCH: compile(RoutePath.SEARCH),
};

// SearchType comes from react-router `match.params` prop, so needs to be translated

export const SearchType = {
  ARTICLES: 'artigos',
  DICTIONARY: 'dicionário',
  IMAGES: 'imagens',
  VIDEOS: 'vídeos',
  DEEPLINK_ARTICLES: 'deeplink-artigos',
  DEEPLINK_IMAGES: 'deeplink-imagens',
  DEEPLINK_VIDEOS: 'deeplink-videos',
  DEEPLINK_DICTIONARY: 'deeplink-dicionario',
};

export const AnimalkingdomType = {
  BYGROUPID: '335990',
  BYHABITATID: '335991',
};

export const Endpoint = {
  BACKEND_API: process.env.REACT_APP_BACKEND_API,
  CDN_URL: process.env.REACT_APP_CDN_URL,
  OPENSEARCH_API: process.env.REACT_APP_OPENSEARCH_API,
  GOOGLE_CLASSROOM: process.env.REACT_APP_GOOGLE_CLASSROOM_URL,
};

export const APIKeys = {
  GOOGLE_PLACES: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  RECAPTCHA_SITEKEY: process.env.REACT_APP_RECAPTCHA_SITEKEY,
};

export const WebStats = {
  ACCOUNT_ID: process.env.REACT_APP_ACCOUNT_ID,
  HOSTNAME_OVERRIDE: process.env.REACT_APP_HOSTNAME_OVERRIDE,
  HOST: process.env.REACT_APP_OVERRIDE_DOMAIN,
};

export const ApplicationVars = {
  ENV: process.env.REACT_APP_ENV,
  PAGE_SIZE: 20,
  DEBOUNCE_RATE: 200,
  RESULTS_LIST_THUMBNAIL_SIZE: '?w=300&h=200',
};

export const Breakpoints = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
};

export const GoogleDriveParameters = {
  CLIENT_ID: '153466901769-d0nej9claicegfvq4i0cabpi3e2vjicc.apps.googleusercontent.com',
  SCOPE: ['https://www.googleapis.com/auth/drive.file'],
  FOLDER_NAME: 'Britannica',
};

export const MediaType = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  INTERACTIVE: 'interactive',
};

export const MediaQuery = {
  BETWEEN: '(min-width: :min) and (max-width: :max)',
  MAX: '(max-width: :max)',
  MIN: '(min-width: :min)',
};

export const ViewportWidth = {
  XS_MAX: `575px`,
  SM_MIN: `576px`,
  SM_MAX: `767px`,
  MD_MIN: `768px`,
  MD_MAX: `991px`,
  LG_MIN: `992px`,
  LG_MAX: `1199px`,
  XL_MIN: `1200px`,
  XL_MAX: `1549px`,
  XXL_MIN: `1550px`,
};

export const AnimalKingdomDefaults = [
  {
    browseId: 2650150,
    defaultBrowseId: 2677522,
  },
  {
    browseId: 2692307,
    defaultBrowseId: 2673509,
  },
  {
    browseId: 2650152,
    defaultBrowseId: 2654584,
  },
  {
    browseId: 2684935,
    defaultBrowseId: 2655429,
  },
  {
    browseId: 2650160,
    defaultBrowseId: 2655546,
  },
  {
    browseId: 2650161,
    defaultBrowseId: 2650161,
  },
  {
    browseId: 2692309,
    defaultBrowseId: 2692309,
  },
  {
    browseId: 2650162,
    defaultBrowseId: 2650162,
  },
  {
    browseId: 2684936,
    defaultBrowseId: 2684936,
  },
  {
    browseId: 2650163,
    defaultBrowseId: 2650163,
  },
  {
    browseId: 2650164,
    defaultBrowseId: 2650164,
  },
  {
    browseId: 2650166,
    defaultBrowseId: 2650166,
  },
  {
    browseId: 2650168,
    defaultBrowseId: 2650168,
  },
  {
    browseId: 2650171,
    defaultBrowseId: 2650171,
  },
  {
    browseId: 2650172,
    defaultBrowseId: 2650172,
  },
  {
    browseId: 2650173,
    defaultBrowseId: 2650173,
  },
];

export const Themes = [
  {
    browseId: 13001,
    title: 'Artes',
    url: '/seção/tema/13001',
    image: artIcon,
    color: 'art',
  },
  {
    browseId: 13043,
    title: 'Língua e literatura',
    url: '/seção/tema/13043',
    image: literatureIcon,
    color: 'literature',
  },
  {
    browseId: 13063,
    title: 'Ciências da Natureza',
    url: '/seção/tema/13063',
    image: naturalScienceIcon,
    color: 'natural-sciences',
  },
  {
    browseId: 13048,
    title: 'História',
    url: '/seção/tema/13048',
    image: historyIcon,
    color: 'history',
  },
  {
    browseId: 13087,
    title: 'Esportes',
    url: '/seção/tema/13087',
    image: sportsIcon,
    color: 'sports',
  },
  {
    browseId: 13011,
    title: 'Geografia',
    url: '/seção/tema/13011',
    image: geographyIcon,
    color: 'geography',
  },
  {
    browseId: 13047,
    title: 'Matemática',
    url: '/seção/tema/13047',
    image: mathIcon,
    color: 'mathematics',
  },
  {
    browseId: 13062,
    title: 'Religião',
    url: '/seção/tema/13062',
    image: religionIcon,
    color: 'religion',
  },
];

export const GameThemes = [
  {
    browseId: 2100,
    title: 'Ciências da Natureza',
    url: RoutePath.BROWSE_GAMES.replace(':theme/:subject', '2100/2110'),
    image: scienceIcon,
    color: 'natural-sciences',
    defaultSubjectId: 2110,
  },
  {
    browseId: 3100,
    title: 'Ciências Humanas',
    url: RoutePath.BROWSE_GAMES.replace(':theme/:subject', '3100/2130'),
    image: socialStudiesIcon,
    color: 'human-sciences',
    defaultSubjectId: 2130,
  },
  {
    browseId: 4100,
    title: 'Linguagens',
    url: RoutePath.BROWSE_GAMES.replace(':theme/:subject', '4100/4110'),
    image: languageIcon,
    color: 'literature',
    defaultSubjectId: 4110,
  },
  {
    browseId: 1400,
    title: 'Matemática',
    url: RoutePath.BROWSE_GAMES.replace(':theme/:subject', '1400/1510'),
    image: mathematicsIcon,
    color: 'mathematics',
    defaultSubjectId: 1510,
  },
];

export const VideoThemes = [
  {
    browseId: 14001,
    title: 'Artes',
    image: `${Endpoint.CDN_URL}/37/202137-107-DD1C93CC.jpg?w=178&h=178`,
    color: 'art',
    url: RoutePath.BROWSE_VIDEOS.replace(':theme', '14001'),
  },
  {
    browseId: 14002,
    title: 'Ciências da Natureza',
    image: `${Endpoint.CDN_URL}/59/199559-107-68B1A169.jpg?w=178&h=178`,
    color: 'natural-sciences',
    url: RoutePath.BROWSE_VIDEOS.replace(':theme', '14002'),
  },
  {
    browseId: 14003,
    title: 'Ciências Humanas',
    image: `${Endpoint.CDN_URL}/90/197490-107-6746FC5A.jpg?w=178&h=178`,
    color: 'human-sciences',
    url: RoutePath.BROWSE_VIDEOS.replace(':theme', '14003'),
  },
  {
    browseId: 14004,
    title: 'Esportes',
    image: `${Endpoint.CDN_URL}/56/140356-107-C0A177D7.jpg?w=178&h=178`,
    color: 'sports',
    url: RoutePath.BROWSE_VIDEOS.replace(':theme', '14004'),
  },
];

export const IndividualAuthType = {
  AUTH_FORGOT_PASSWORD: 'forgotpassword',
  AUTH_SIGNIN: 'signin',
  AUTH_SIGNUP: 'signup',
};

export const BrowseType = {
  AZ: 'az',
  ANIMALS: 'animais',
  BIOGRAPHIES: 'biografias',
  DYK: 'voceconhece',
  GAMES: 'jogos',
  THEMES: 'tema',
  VIDEOS: 'vídeos',
  IMAGES: 'imagens',
  SUBJECT: 'tema',
  HELP: 'ajuda',
};

export const Env = {
  ENVIRONMENT: process.env.REACT_APP_ENV,
  VERSION: process.env.REACT_APP_VERSION,
};

export const TopicColorsMiddle = {
  0: '#06803d',
  1: '#0ead2d',
  2: '#5bd93d',
  3: '#8cf005',
  // 4: '#ddfa00'
  4: '#fee000',
};

export const TopicColorsElementary = {
  0: '#c45500',
  1: '#eb5150',
  2: '#ecd834',
  3: '#8fd666',
  4: '#72b8e2',
  5: '#a49be2',
};

const browseAtlasToPath = compile(RoutePath.BROWSE_ATLAS);

export const TopicsMiddleHigh = [
  {
    id: 0,
    title: 'Pesquisa A-Z',
    iconL2: azL2,
    iconL3: azL3,
    url: RoutePath.BROWSE_ALPHA.replace(
      ':alphaType/:alphaLetter/:pageNumber',
      `${BrowseType.AZ}/a/1`
    ),
  },
  {
    id: 1,
    title: 'Pesquisa de imagens',
    iconL2: imagensL2,
    iconL3: imagensL3,
    url: RoutePath.BROWSE_ALPHA.replace(
      ':alphaType/:alphaLetter/:pageNumber',
      `${BrowseType.IMAGES}/a/1`
    ),
  },
  {
    id: 2,
    title: 'Videoteca',
    iconL2: videotecaL2,
    iconL3: videotecaL3,
    url: RoutePath.BROWSE_ALPHA.replace(
      ':alphaType/:alphaLetter/:pageNumber',
      `${BrowseType.VIDEOS}/a/1`
    ),
  },
  {
    id: 3,
    title: 'Biografias',
    iconL2: biografiasL2,
    iconL3: biografiasL3,
    url: RoutePath.BROWSE_ALPHA.replace(
      ':alphaType/:alphaLetter/:pageNumber',
      `${BrowseType.BIOGRAPHIES}/a/1`
    ),
  },
  {
    id: 4,
    title: 'Descubra o Brasil',
    iconL2: brasilL2,
    iconL3: brasilL3,
    url: browseAtlasToPath({ isoCode: 'br' }),
  },
];

export const TopicsElementary = [
  {
    id: 0,
    title: 'Busca A-Z',
    icon: azL1,
    url: RoutePath.BROWSE_ALPHA.replace(
      ':alphaType/:alphaLetter/:pageNumber',
      `${BrowseType.AZ}/a/1`
    ),
  },
  {
    id: 1,
    title: 'Biografias',
    icon: biografiasL1,
    url: RoutePath.BROWSE_ALPHA.replace(
      ':alphaType/:alphaLetter/:pageNumber',
      `${BrowseType.BIOGRAPHIES}/a/1`
    ),
  },
  {
    id: 2,
    title: 'Videoteca',
    icon: videotecaL1,
    url: RoutePath.BROWSE_ALPHA.replace(
      ':alphaType/:alphaLetter/:pageNumber',
      `${BrowseType.VIDEOS}/a/1`
    ),
  },
  {
    id: 3,
    title: 'Atlas mundial',
    icon: atlasL1,
    url: browseAtlasToPath(),
  },
  {
    id: 4,
    title: 'Descubra o Brasil',
    icon: brasilL1,
    url: browseAtlasToPath({ isoCode: 'br' }),
  },
  {
    id: 5,
    title: 'Jogos',
    icon: jogosL1,
    url: RoutePath.BROWSE_GAMES.replace(':theme/:subject', '2100/2110'),
  },
];
